#root {
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  width: 100%;
}

.content {
  flex: 1;
  overflow: hidden;
  position: relative;
}
