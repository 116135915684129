.container {
  padding: 20px;
  flex: 1;
  height: 100%;
  overflow-y: scroll;
}

.resultsContainer {
  display: flex;
  flex-wrap: wrap;
}

.result {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
  height: 40px;
  width: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 0 10px 10px 0;
  user-select: none;
}

.container > h3 {
  margin: 30px 0 0 0;
}

.result:hover {
  background-color: darken($color: white, $amount: 5);
}
