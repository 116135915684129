.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 20px 0 80px 0;
  cursor: default;

  > * {
    padding-left: 20px;
    padding-right: 20px;
  }

  > h3 {
    margin-top: 30px;
    margin-bottom: 10px;
  }
}

.activationContainer {
  > h4 {
    margin-bottom: 8px;
  }
}

.inputContainer {
  padding: 0 10px;
  display: flex;
  margin-bottom: 10px;
}

.botContainer {
  font-size: small;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.botContainer:hover {
  background-color: darken($color: whitesmoke, $amount: 5);
}

.botTitle {
  font-size: medium;
  font-weight: bold;
}
.botStatus {
  font-size: medium;
  margin-bottom: 8px;
}

.selected {
  background-color: lightgray;
}

.profile {
  font-size: small;
}
