.infoHeader {
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  > * {
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 250px;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 10px;
  }
}
