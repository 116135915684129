.container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.inputContainer {
  display: flex;
  position: relative;
  flex: 1;
  align-items: center;
  > button,
  a {
    margin-left: 10px;
  }
}

.profileIcon {
  margin-right: 10px;
}

.progress {
  margin-left: 10px;
}

.alert {
  position: absolute;
  margin-top: 5px;
  top: 40px;
}
