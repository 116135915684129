.container {
  position: relative;
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  cursor: default;
}

.footer:hover {
  background-color: darken($color: lightgray, $amount: 5);
}
