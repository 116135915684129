.container {
  // background: blue;
}

.jsonContainer {
  display: block;
  position: relative;
  > textarea {
    flex: 1;
    width: 100%;
    height: 100px;
    padding: 8px;
    box-sizing: border-box;
  }
  > button {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}

.diffContainer {
  > textarea {
    height: 300px;
  }
}