.container {
    position: relative;
    display: block;
    padding: 8px 16px;
    background: whitesmoke;
    border: 1px solid lightgray;
    border-radius: 8px;
}

.definitionAndContainer {
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 0 3px 3px 0;
    margin-left: 9px;
    box-shadow: rgb(149, 165, 166) -8px 0px;
}

.definitionOrSpacer {
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 4px 16px 4px 16px;
}

.definitionOrSpacer::after {
    background-color: lightgray;
    content: "";
    margin-left: 10px;
    height: 1px;
    width: 100%;
}

.definitionAddOr,
.definitionAddAnd {
    text-decoration: underline;
    color: rgb(25, 188, 156);
    border: none;
    line-height: 20px;
    background: none;
    font-size: 14px;
    user-select: none;
    outline: none;
    padding: 0px 16px 16px 16px;
}

.definitionAddAnd {
    border: 1px solid lightgray;
    border-top: 0;
    background-color: white;
    border-radius: 0 3px 3px 0;
    margin-left: 9px;
    box-shadow: rgb(117, 141, 205) -8px 0px;
    padding: 0;
    > button {
        text-decoration: underline;
        color: rgba(25, 188, 155, 0.596);
        border: none;
        line-height: 20px;
        background: none;
        font-size: 14px;
        user-select: none;
        outline: none;
        padding: 16px;
    }
}

.definitionAndSpacer {
    padding: 16px;
    margin-left: 4px;
    border-left: 2px dashed rgb(229, 229, 229);
    font-weight: bold;
}

.conditionContainer {
    padding: 16px;
    position: relative;
}

.deleteCondition {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: default;
}

.conditionBuilderContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    > * {
        flex: none;
        margin-right: 10px;
    }
}

.buttonContainer {
    display: flex;
}

.buttonContainer > button {
    background-color: white;
}

.debugView {
    margin-top: 0;
    background: white;
    padding: 10px;
    border-radius: 8px;
    border: 1px dotted gray;
}