.container {
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.statusContainer {
  border-radius: 6px;
  border: 1px solid #e1e4e8;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: lightgray;
  margin: 12px 0 24px 0;
}

.container h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.resultsContainer {
  display: flex;
  flex-wrap: wrap;
}

.actionContainer {
  display: flex;
  > * {
    display: flex;
    width: 280px;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    border-radius: 6px;
    border: 1px solid #e1e4e8;
    padding: 16px;
    margin-top: 10px;
    margin-right: 10px;
    align-items: stretch;
  }
}

.result {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 2px 10px 0 rgba(0, 0, 0, 0.19);
  height: 40px;
  width: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  margin: 0 10px 10px 0;
  user-select: none;
}

.container > h3,
h4 {
  margin: 0;
  margin-bottom: 8px;
}

.result:hover {
  background-color: darken($color: white, $amount: 5);
}

.comment {
  flex: none;
  width: 654px;
}

.actionContainerVivd {
  > * {
    width: 600px;
  }
}

.commentVivid {
  align-self: stretch;
}
