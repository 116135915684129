.container {
  padding: 8px;
}

.availableContainer {
  padding: 8px;
  border: 1px solid lightgray;
}

.ageArrayHeader,
.ageArrayValues {
  font-size: small;
  border: 1px solid gray;
  padding: 4px 6px;
  height: 20px;
  display: flex;
  align-items: center;
  border-right: 0;
}

.ageArrayHeader {
  padding-left: 8px;
  border-bottom: 0;
}

.ageContainer {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
}

.ageCol {
  flex: none;
}

.ageContainer > .ageCol:last-of-type {
  border-right: 1px solid gray;
}

.editorContainer {
  padding: 8px;
  border: 1px solid lightgray;
}

.costTableContainer {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: scroll;
}
