.container {
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  height: 100%;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 1000px;
}

.stepContainer {
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 8px;
  div,
  p {
    font-size: small;
  }
}

.ageArrayHeader,
.ageArrayValues {
  font-size: small;
  border: 1px solid gray;
  padding: 4px 6px;
  height: 20px;
  display: flex;
  align-items: center;
  border-right: 0;
}

.ageArrayHeader {
  padding-left: 8px;
  border-bottom: 0;
}

.ageContainer {
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: scroll;
}

.ageCol {
  flex: none;
}

.ageContainer > .ageCol:last-of-type {
  border-right: 1px solid gray;
}

.editorContainer {
  padding: 8px;
  border: 1px solid lightgray;
}

.costTableContainer {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: scroll;
}

.costTableHeader {
  display: flex;
  align-items: center;
  > *:first-child {
    font-size: medium;
    font-weight: 500;
  }
}

.intervallSelector {
  padding-left: 4px;
  display: flex;
  color: rgb(160, 160, 160);
}

.intervallSelected {
  color: black;
}
