.container {
    position: relative;
    display: block;
    padding: 8px 16px;
    background: whitesmoke;
    border: 1px solid lightgray;
    border-radius: 8px;
}

.buttonContainer {
    display: flex;
}