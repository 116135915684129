.actionContainer {
  display: flex;
  > * {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    border-radius: 6px;
    align-items: flex-start;;
    border: 1px solid #e1e4e8;
    padding: 16px;
    margin-top: 10px;
    margin-right: 10px;
  }
}
