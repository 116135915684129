.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  padding-bottom: 80px;
  > * {
    margin-bottom: 10px;
  }
}

.container {
  pre {
    box-sizing: border-box;
    width: 100%;
    border: 1px dotted black;
    overflow-x: scroll;
    font-size: 14px;
    padding: 10px;
    margin: 0;
    background: whitesmoke;
    tab-size: 2;
    font-size: small;
    border-radius: 4px;
    text-overflow: wrap;
    position: relative;
    white-space: pre-wrap;
  }
}

.label {
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 4px;
}

.snippet {
  cursor: default;
  user-select: none;
}

.snippet:hover {
  background-color: darken($color: whitesmoke, $amount: 4);
}

.snippetIcon {
  position: absolute;
  top: 4px;
  right: 4px;
  color: rgb(104, 104, 104);
}

.snippetClicked {
  background: rgba(46, 139, 86, 0.247)!important;
}