.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 20px 20px 20px;
  overflow-y: scroll;

  h2,h3 {
    margin: 0;
  }
}

.loginContainer {
  font-size: small;
}

.section {
  display: flex;
  flex-direction: column;
  max-width: 800px;

  h1 {
    margin-top: 0;
    margin-bottom: 20px;
  }

  form {
    display: flex;
    flex: 1;
    input {
      margin-right: 10px;
    }
  }
}

.endpointsContainer {
  display: flex;
}

.card {
  display: flex;
  flex-direction: column;
  padding: 10px;
  box-sizing: content-box;
  margin: 0 5px 5px 0;
  border: 1px solid lightgray;
  user-select: none;
  border-radius: 4px;
  width: 180px;
  height: 180px;
  > * {
    font-size: small;
    margin-bottom: 4px;
  }
  overflow: hidden;
}

.card:hover {
  background-color: darken($color: white, $amount: 10);
}

.activeEndpoint {
  background-color: #ebeeff;
}

.activeEndpoint:hover {
  background-color: darken($color: #ebeeff, $amount: 5);
}

.cardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: medium;
  margin-bottom: 10px;
  font-weight: 600;
}

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: lightgray;
  margin: 4px 0;
}

.buttonContainer {
  display: flex;
}

.subtitle {
  font-size: small;
  width: 100%;
  overflow-wrap: break-word;
}

.settingsContainer {
  display: block;
  align-items: flex-start;
  margin-top: 40px;
}

.spacer {
  flex: 1;
}

.settings {
  display: flex;
  align-items: flex-start;
}

.credentialContainer {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 400px;
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 20px;
  :first-child {
    font-weight: bold;
    margin-bottom: 10px;
  }
  > * {
    margin-bottom: 6px;
  }
}
