body, textarea {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4 {
  // font-family: Gilroy, OpenSans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  //   Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-family: Gilroy;
}

@font-face {
  font-family: 'Gilroy';
  font-style: 'normal';
  font-weight: 400;
  src: url(./fonts/Gilroy-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: 'normal';
  font-weight: 600;
  src: url(./fonts/Gilroy-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Gilroy';
  font-style: 'normal';
  font-weight: 700;
  src: url(./fonts/Gilroy-Bold.woff) format('woff');
}

a {
  text-decoration: none;
}

textarea {
  padding: 8px 12px;
  flex: 1;
  outline: none;
  border-radius: 4px;
  border: solid 1px lightgray;
  font-size: medium;
}

select {
  appearance: none;
  padding: 8px 12px;
  flex: 1;
  outline: none;
  border-radius: 4px;
  border: solid 1px lightgray;
  font-size: medium;
}