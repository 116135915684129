.container {
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
}

.content {
  display: block;
  overflow-y: scroll;
  flex: 1;
  padding: 20px 0;
}

.message {
  border: 2px solid gray;
  border-radius: 10px;
  margin: 0 50px 5px 10px;
  overflow: hidden;
  font-size: small;
}

.message.userMessage {
  margin: 0 10px 5px 50px;
  color: #320ccd;
  border-color: #6756fb;
}

.message.expanded {
  margin: 0 10px 5px 10px;
}

.messageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
  > * {
    margin-right: 5px;
  }
}

.messageTitle {
  font-size: medium;
  flex: 1;
}

.messageContent {
  padding: 4px;
}

.codePreviewButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  font-weight: bold;
  font-size: small;
  border: 1px solid lightgray;
  border-radius: 12px;
  user-select: none;
  background: white;
}

.codePreviewButton:hover {
  background: whitesmoke;
}

.codePreview {
  padding: 6px 6px;
  margin: 0;
  background: whitesmoke;
  border: 1px dotted gray;
  white-space: pre-wrap;
  color: black;
}
