.container {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.dateContainer {
  display: flex;
}

.dateRangeContainer {
  display: flex;
  flex: none;
  justify-content: left;
  align-items: center;
}

.dateRangeSelection {
  flex: none;
  min-width: 150px;
}
