.content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  > * {
    margin-bottom: 8px;
  }
}

.content {
  table {
    margin: 40px 0;
    table-layout: fixed;
  }
  table,
  td,
  tr {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 10px;
  }
  td {
    width: 50%;
    font-size: small;
  }
}
