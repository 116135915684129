.container {
    height: 100%;
}

.content {
    display: block;
    padding: 0 10px 20px 10px;
    height: 100%;
    overflow-y: scroll;
}

.row {
    display: flex;
    align-items: baseline;
    padding: 4px 0;
    :first-child {
        width: 80px;
    }
}

.nodeIdsContainer {
    border: 1px solid lightgray;
    padding: 10px;
    margin: 10px 0;
}

.nodeIdContainer {
    display: flex;
    align-items: baseline;
    font-size: small;
    :nth-child(1) {
        width: 20px;
    }
    :nth-child(2) {
        width: 300px;
    }
    :nth-child(3) {
        width: 300px;
        flex: 1;
    }
    :nth-child(4) {
        width: 90px;
    }
}

.sideNavContent {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    > h3 {
        padding: 0 10px;
    }
}

.funnelConfig {
    padding: 4px 10px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    cursor: default;
}

.selected {
    background: lightgray;
}