.formContainer {
    display: grid;
    grid-template-columns: 90px 1fr;
    row-gap: 4px;
    align-items: center;
    > * {
      font-size: 14px;
      margin: none;
      box-sizing: border-box;
    }
    > *:nth-child(odd) {
      font-weight: 500;
    }
    > *:nth-child(even) {
      min-height: 34px;
    }
    > div:nth-child(even) {
      background-color: white;
      border: 1px dotted lightgray;
      border-radius: 4px;
      font-weight: 500;
      line-height: 16px;
      padding: 8px 12px;
    }
}