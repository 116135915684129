.container {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.header {
  height: 45px;
  background-color: whitesmoke;
  display: flex;
  padding: 0 20px;
  box-sizing: border-box;
  align-items: center;
  font-weight: bold;
  border-bottom: 1px solid lightgray;
}

.header:hover {
  background-color: darken($color: whitesmoke, $amount: 4);
}

.content {
  background-color: white;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
}

.title {
  flex: 1;
}
