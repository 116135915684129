.outerContainer {
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
}

.content {
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.container {
  width: 46px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
}

.expanded {
  min-width: 300px;
  max-width: 50%;
}

.sideNavContent {
  overflow-y: scroll;
  flex: 1;
}

.collapsed:hover {
  background-color: darken($color: whitesmoke, $amount: 5);
}

.footer {
  position: absolute;
  display: flex;
  flex: none;
  align-items: center;
  bottom: 0;
  padding: 10px 5px;
  border-top: 1px solid lightgray;
  width: 100%;
  font-weight: 500;
  cursor: default;
  color: rgb(70, 70, 70);
  background-color: whitesmoke;
}

.footer:hover {
  background-color: darken($color: whitesmoke, $amount: 5);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
}

.iconCollapsed {
  transform: rotate(180deg);
}
