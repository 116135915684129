.titleContainer {
    display: flex;
    align-items: center;
}

.addContainer {
    display: flex;
    width: 100%;
}

.summaryContainer {
    display: flex;
    flex-wrap: wrap;
}

.summaryItem {
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 4px;
    width: 150px;
    // height: 50px;
    align-items: center;
    text-align: center;
    padding: 8px 4px;
    margin: 4px;
    > *:nth-child(1) {
      font-weight: bold;
      margin-bottom: 4px;
    }
    > *:nth-child(2) {
      font-size: small;
    }
}

.dropContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border: 1px dotted lightgray;
    background-color: whitesmoke;
    border-radius: 4px;
    color: gray;
}

.dropContainer.draggedOver {
    background-color:  #5fcc8040;   
}

.dropContainer.draggedOver {
    background-color:  #5fcc8040;
}

.dropContainerFull:hover {
    background-color:  #e9605b40;
    cursor: default;
}

.error {
    font-size: small;
}

.summaryFailedContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}