.content {
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.table {
  display: flex;
  flex: 1;
  flex-basis: 1;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  margin-bottom: 45px;
  padding: 40px 20px 0 20px;
}

.chat,
.refund,
.assessment {
  display: flex;
  flex: none;
  width: 620px;
  flex-basis: 1;
  margin-bottom: 45px;
  padding-top: 40px;
}

.refund {
  width: 300px;
  overflow-y: scroll;
  padding: 10px 0 10px 0;
}

.assessment {
  width: 860px;
}

.eric {
  position: absolute;
  background-color: red;
}

.dataButtons {
  position: absolute;
  top: 0;
  right: 0;
}
