.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  // justify-content: center;
  margin-top: 100px;
  padding: 40px;
  height: 100%;
  overflow-y: scroll;
}

.content {
  width: 800px;
  display: flex;
}

.navigation {
  display: flex;
  flex-direction: column;
}
