.element {
  flex: 0;
}

.xsmall {
  height: 1px;
  width: 1px;
  min-height: 1px;
  min-width: 1px;
}

.xxsmall {
  height: 2px;
  width: 2px;
  min-height: 2px;
  min-width: 2px;
}

.xsmall {
  height: 4px;
  width: 4px;
  min-height: 4px;
  min-width: 4px;
}

.small {
  height: 8px;
  width: 8px;
  min-height: 8px;
  min-width: 8px;
}

.medium {
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
}

.large {
  height: 24px;
  width: 24px;
  min-height: 24px;
  min-width: 24px;
}

.xlarge {
  height: 40px;
  width: 40px;
  min-height: 40px;
  min-width: 40px;
}

.xxlarge {
  height: 64px;
  width: 64px;
  min-height: 64px;
  min-width: 64px;
}

.xxxlarge {
  height: 104px;
  width: 104px;
  min-height: 104px;
  min-width: 104px;
}

.expander {
  flex: 1;
}
