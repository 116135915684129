.container {
  display: flex;
  flex-wrap: nowrap !important;
  overflow-x: scroll;
}

.overviewContainer {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: none;
  overflow: hidden;
  box-sizing: border-box;
  padding: 8px;
  width: 500px;
  border: 1px solid gray;
  border-radius: 8px;
}

.overviewContainer:not(:last-of-type) {
  margin-right: 10px;
}

.overviewHeader {
  position: relative;
  height: 90px;
  border-bottom: 1px solid gray;
  > table {
    font-size: small;
  }
}

.year {
  position: absolute;
  top: 10px;
  right: 0px;
  margin: 0;
  padding: 0;
}

.faxContainer {
  display: flex;
  border-bottom: 1px solid gray;
  padding-top: 10px;
  margin-bottom: 10px;
  font-size: medium;
  text-decoration: underline;
  font-weight: bold;
  > span {
    margin-right: 4px;
  }
}
