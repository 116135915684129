.faxCard {
  width: 500px;
}

.faxHeader {
  display: flex;
  margin-bottom: 10px;
  cursor: default;
  > * {
    margin-right: 10px;
  }
}

.faxObject {
  width: 500px;
  height: 400px;
}
