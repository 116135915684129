.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  > h4 {
    margin: 20px 0 5px 0;
  }
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  > * {
    margin-right: 2px;
  }
}

.query {
  flex: 1;
  font-size: small;
}
