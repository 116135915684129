.container {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid lightgray;
    margin: 0 4px 4px 0;
    font-size: small;
    cursor: default;
}

.active {
    background-color: lighten($color: lightgray, $amount: 10);
}

.holder {
    font-weight: bold;
}

.iban {
    // font-size: x-small;
}

.bankName {
    // font-size: x-small;
}