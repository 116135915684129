.container {
  display: block;
}

.header {
  margin-bottom: 20px;
}

.transactions {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
}

.creditCard {
  font-size: small;
}
