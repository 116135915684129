.container {
  height: 100%;
  padding: 20px;
  overflow-y: scroll;
  height: 100%;
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 1000px;
}

.stepContainer {
  margin-bottom: 20px;
  padding: 20px;
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 8px;
  div,
  p {
    font-size: small;
  }
}

.dateRangeContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > * {
    flex: none;
  }
  > div {
    font-size: medium;
  }
}
