.container {
  table {
    background-color: white;
  }
  margin-bottom: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.valid {
  color: green;
}
