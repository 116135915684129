:root {
  display: block;
}

.container {
  padding: 20px;
  overflow-y: scroll;
  height: 100%;
}

.divider {
  height: 1px;
  width: 100%;
  background-color: lightgray;
  margin: 12px 0 24px 0;
}

.actionContainer {
  display: flex;
  > * {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    flex: 1;
    border-radius: 6px;
    align-items: flex-start;
    border: 1px solid #e1e4e8;
    padding: 16px;
    margin-top: 10px;
    margin-right: 10px;
  }
}

.couponContainer {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
  width: 100%;
  > * {
    overflow: hidden;
    font-size: small;
  }
}

.orderNoContainer {
  width: 100%;
  background: whitesmoke;
  font-size: small;
  > div {
    height: 16px;
    padding: 4px;
    display: flex;
    flex-direction: row;
    > * {
      margin-right: 10px;
    }
    > :nth-child(1) {
      width: 40px;
      display: flex;
      justify-content: center;
      user-select: none;
      cursor: default;
    }
    > :nth-child(2) {
      width: 200px;
    }
    > :nth-child(3) {
      width: 200px;
    }
    > :nth-child(4) {
      width: 200px;
    }
    > :nth-child(5) {
      flex: 1;
    }
  }
}

.orderListInputContainer {
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
  }
}

.orderListButton {
  width: 300px;
}
