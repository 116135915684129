.container {
    display: block;
    display: flex;
    background: white;
    flex-direction: column;
    position: relative;
    border: 1px solid lightgray;
    border-radius: 8px;
    overflow: hidden;
}

.expansionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: default;
    user-select: none;
    padding: 8px 16px 8px 16px;
    font-weight: bold;
}

.expansionHeader:hover {
    background: darken($color: #FFFFFF, $amount: 2);
}

.expansionContent {
    border-top: 1px solid lightgray;
    padding: 16px;
}
