.container {
  flex: 1;
  overflow-y: scroll;
  overflow-x: scroll;
  box-sizing: border-box;
  width: 100%;
  background: whitesmoke;
  margin-right: 10px;
  padding: 10px;
  * {
    font-family: monospace;
  }
  > table {
    margin-bottom: 100px;
    td {
      padding: 2px 4px 2px 4px;
    }
  }
  h3 {
    margin-top: 10px;
  }
  h4 {
    margin: 0;
  }
}

.text {
  width: 90%;
  height: 400px;
  margin: 10px;
}

.divider {
  height: 100px;
}

.row {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid gray;
  padding: 5px 10px;
}

.number {
  flex: none;
  width: 100px;
}

.description {
  flex: none;
  width: 220px;
  margin-right: 10px;
}

.value {
  flex: 1;
  margin-right: 10px;
  white-space: pre-wrap;
}

.pin {
  user-select: none;
}
