.orderListInputContainer {
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
    flex: none;
  }
}

.orderListButton {
  width: 300px;
}

.couponSelectionContainer {
  position: relative;
}

.couponSelectionSummary {
  display: flex;
  border: solid 1px lightgray;
  border-radius: 4px;
  padding: 8px 12px;
  flex: 1;
  cursor: default;
  min-width: 300px;
}

.couponSelectionSummaryCoupon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  font-size: small;
  padding: 0 12px;
  border-radius: 12px;
  background-color: rgba(92, 92, 255, 0.42);
  margin-right: 2px;
  display: flex;
}

.couponSelectionList {
  position: absolute;
  background: white;
  padding: 4px 4px;
  border: 1px solid gray;
  border-radius: 4px;
  font-size: small;
  max-height: 300px;
  z-index: 100;
  overflow: hidden;
  overflow-y: scroll;
}

.couponSelectionCoupon {
  display: flex;
}
