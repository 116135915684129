.container {
  padding: 5px 20px;
  border-bottom: 1px solid whitesmoke;
  display: flex;
  align-items: center;
}

.iconContainer {
  display: flex;
  align-items: center;
}

h3 {
  margin: 0;
  cursor: default;
}

.icon {
  width: 30px;
  height: 30px;
  position: relative;
}

.spacer {
  flex: 1;
}

.endpoint {
  font-size: medium;
  cursor: default;
}

.searchContainer {
  flex: 1;
  margin: 0 40px;
}
