.container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding: 20px;
  padding-bottom: 100px;
  position: relative;
  box-sizing: border-box;

  > h2 {
    margin-top: 40px;
  }
}

.actions {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 10px;
}

.actionContainer {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  padding: 10px;
  border: 1px solid lightgray;
  margin-bottom: 20px;
  > input {
    flex: none;
  }
  > * {
    margin-bottom: 10px;
  }
}

.devices {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 10px;
}

.invites {
  display: grid;
  grid-template-columns: repeat(auto-fill, 370px);
  grid-gap: 10px;
}

.vastCard {
  display: flex;
}

.vastCreateContainer {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 4px;
  padding: 4px;
  align-items: center;
  > * {
    font-size: small;
  }
  > *:nth-child(odd) {
    font-weight: bold;
  }
  > input {
    padding: 2px 2px;
  }
}
