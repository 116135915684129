.container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px 20px 0 20px;
  overflow-y: scroll;
  height: 100%;
  align-items: center;
  box-sizing: border-box;
}

.header {
  display: flex;
  align-items: center;
  height: 60px;

  > * {
    margin-right: 10px;
  }
  margin-bottom: 30px;
}

.table {
  overflow-x: scroll;
  width: 100%;
  padding-bottom: 10px;
  align-self: flex-start;
  margin-bottom: 20px;
}

.pager {
  margin-bottom: 40px;
}

.labelValue {
  display: flex;
  align-items: center;
  :nth-child(1) {
    margin-right: 4px;
  }
  :nth-child(2) {
    font-weight: bold;
  }
}
