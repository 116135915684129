.orderListInputContainer {
  display: flex;
  align-items: center;
  > * {
    margin-right: 10px;
  }
  > input {
    flex: none;
  }
  > select {
    min-width: 200px;
  }
}

.orderListButton {
  width: 300px;
}
