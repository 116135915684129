.rowContainer {
  display: flex;
  flex-direction: column;
  background-color: white;
  font-size: small;
}

.rowContainer:nth-of-type(odd) {
  background-color: rgb(238, 238, 238);
}

.row {
  display: flex;
}

.row.header {
  font-weight: bold;
}

.row.hasSnippet {
  font-weight: bold;
}

.colExpand {
  height: 16px;
  flex-basis: 20px;
  display: flex;
  justify-content: center;
  cursor: default;
  user-select: none;
}

.colDesc {
  height: 16px;
  overflow: hidden;
  flex-basis: 300px;
  text-overflow: ellipsis;
}

.colValue {
  height: 16px;
  flex-basis: 120px;
}

.colValue.negative {
  color: red,
}

.colValue.positive {
  color: green,
}

.expandedContainer {
  display: block;
  border: 1px dotted gray;
  padding: 6px;
  background-color: whitesmoke;
}

.expandRow {
  display: flex;
  margin-bottom: 4px;
  > :first-child {
    width: 160px;
    flex: none;
    font-weight: bold;
  }
  > textarea, input {
    flex: 1;
  }
  > textarea {
    min-height: 100px;
  }
}