.input {
  padding: 8px 12px;
  flex: 1;
  outline: none;
  border-radius: 4px;
  border: solid 1px lightgray;
  font-size: medium;
}

.small {
  padding: 2px 4px;
  font-size: small;
}

.title {
  border: none;
  font-family: Gilroy, OpenSans, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  font-size: x-large;
}
