.container {
  width: 100%;
}

.table {
  word-break: break-all;
  margin-bottom: 20px;
  border-collapse: collapse;
  width: 100%;
  background-color: white;
}

.rowHover > tbody > *:hover {
  background: whitesmoke;
  cursor: default;
}

.default {
  tbody > :nth-child(odd) {
    background-color: rgb(238, 238, 238);
  }

  th {
    text-align: start;
    font-size: small;
  }
  td {
    text-overflow: wrap;
    max-width: 400px;
    min-width: 50px;
  }
  th,
  td {
    font-size: small;
    padding: 2px 4px;
  }
}

.material {
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  thead, tbody > * {
    height: 40px;
    padding: 0 10px;
  }

  thead, tbody > :not(:last-child) {
    border-bottom: 1px solid lightgray;
  }

  th {
    text-align: start;
    font-size: small;
  }
  td {
    text-overflow: wrap;
    max-width: 400px;
    min-width: 50px;
  }
  th,
  td {
    font-size: small;
    padding: 16px 8px;
  }
}
