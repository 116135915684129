.container {
  overflow-y: scroll;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  h3 {
    margin-top: 20px;
    margin-bottom: 8px;
  }
}

.orderActionContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.orders {
  flex: 1;
}

.actions {
  display: flex;
  flex: none;
  margin-bottom: 5px;
  margin-top: 20px;
  padding: 4px;
  border: 1px solid gray;
  background-color: whitesmoke;
  align-items: center;
  button {
    background-color: white;
    margin-right: 10px;
    font-size: small;
  }
}

.sectionContainer {
  > div {
    display: flex;
    flex-wrap: wrap;
    font-size: small;
    > * {
      margin: 0 10px 10px 0;
    }
  }
}

.credit {
  border: 1px solid gray;
  padding: 10px;
  width: 300px;
  overflow: 'none';
  font-size: small;
}

.checkbox {
  height: 2px;
  width: 10px;
}

.comment {
  font-size: small;
}
