.contentContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.inputContainer {
  display: flex;
  flex: 1;
  align-items: center;
  > * {
    width: 100%;
  }
}

.preview {
  flex: none;
  width: 350px;
  flex: none;
  margin-left: 20px;
}

.createButton {
  width: 300px;
}

.frame {
  aspect-ratio: 9/19.5;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border: 6px solid black;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
}

.previewAcceptContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  top: 300px;
  box-sizing: border-box;
  padding: 20px;
  text-align: center;
  > .previewTitleHeader {
    font-weight: 500;
    font-size: small;
    color: #050505;
  }
  > .previewTitle {
    font-weight: 600;
    font-size: x-large;
    color: #0ba251;
  }
  > .previewDescription {
    font-size: 13px;
    color: #050505;
  }
}

.previewAcceptHint {
  color: #b40616;
  position: absolute;
  bottom: 134px;
  left: 20px;
}
