.titleContainer {
  display: flex;
  align-items: center;
}

.addContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.actionContainer {
  display: flex;
  align-items: center;
}

.dropContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: 1px dotted lightgray;
  background-color: whitesmoke;
  border-radius: 4px;
  color: gray;
}

.error {
  font-size: small;
}
