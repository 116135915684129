.highlightContainer {
    display: flex;
    border-top: 1px solid rgb(223, 223, 223);
    border-bottom: 1px solid rgb(223, 223, 223);
    padding: 16px 8px;
}

.highlightGrid {
    display: grid;
    flex: none;
    max-width: 100%;
    grid-template-columns: repeat(auto-fill, 170px);
    column-gap: 16px;
    row-gap: 16px;
    > * {
        background-color: white;
    }
}

.highlightNumber {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 8px;

    > h1 {
        margin: 0;
    }

    > :nth-child(2) {
        font-size: small; 
        text-align: center; 
        color: #555555;
    }
}