.container {
  padding: 20px;
  flex: 1;
  height: 100%;
  overflow-y: scroll;
}

.documentContainer {
  display: block;
  font-size: small;
  border: 1px solid gray;
  padding: 10px;
  margin-bottom: 10px;
}

.container > h3 {
  margin: 30px 0 0 0;
}

.result:hover {
  background-color: darken($color: white, $amount: 5);
}

.codeContainer {
  border: 1px dotted gray;
  > * {
    padding: 4px;
  }
}

.codePreviewButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  font-weight: bold;
  font-size: small;
  border: 1px solid lightgray;
  border-radius: 12px;
  user-select: none;
  background: white;
}

.codeContainerHeader:hover {
  background: whitesmoke;
}

.codePreview {
  padding: 6px 6px;
  margin: 0;
  white-space: pre-wrap;
  color: black;
  border-top: 1px solid lightgray;
}

.codeContainerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  user-select: none;
}