.container {
  display: grid;
  grid-template-columns: 90px 1fr;
  row-gap: 4px;
  padding: 4px;
  align-items: baseline;
  > * {
    font-size: small;
    height: 20px;
  }
  > *:nth-child(odd) {
    font-weight: bold;
  }
  > input {
    padding: 2px 2px;
  }
}