.botContainer {
  font-size: small;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgray;
}

.botContainer:hover {
  background-color: darken($color: whitesmoke, $amount: 5);
}

.botTitle {
  font-size: medium;
  font-weight: 500;
}

.botStatus {
  font-size: medium;
}

.botStatus.selected, .botTitle.selected {
  font-weight: bold;
}

.botContainer.selected {
  background-color: lightgray;
}
