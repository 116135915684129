.container {
  padding: 20px;
  height: 100%;
  padding-bottom: 100px;
  box-sizing: border-box;
  overflow-y: scroll;
  font-size: small;
}

.cardContainer > * {
  display: flex;
  flex-wrap: wrap;
}

.topRow {
  > * {
    margin: 10px;
    height: 150px;
    width: 220px;
    overflow-y: scroll;
  }
}

.bottomRow {
  > * {
    margin: 10px;
    height: 250px;
    width: 220px;
    overflow-y: scroll;
  }
}

.cardMedium {
  width: 400px;
}

.cardLarge {
  width: 800px;
}

.inputContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.inputComment {
  min-width: 400px;
}

.supportInfos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  > h3 {
    margin-bottom: 10px;
  }
}

.supportInfos > *:last-child {
  background-color: #ebeeff;
}

.supportInfoContainer {
  background-color: whitesmoke;
  border: 1px dotted gray;
  padding: 10px;
  width: 350px;
  margin-bottom: 10px;
}

.supportHeader {
  font-size: small;
  font-weight: bold;
  margin-bottom: 5px;
}

.supportComment {
  overflow: hidden;
  overflow-wrap: break-word;
}

.supportPrev {
  margin-top: 5px;
  font-size: small;
}

.infoHeader {
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
  > * {
    margin-right: 10px;
    margin-bottom: 10px;
    min-width: 250px;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 10px;
  }
}

.devicesCard {
  width: 600px;
}

.devices {
  display: flex;
  flex-direction: column;
  > * {
    margin-bottom: 10px;
  }
}

.mentionContainer {
  margin: 16px 0 10px 0;
  > h4 {
    margin: 0;
    margin-bottom: 4px;
  }
}

.faxCard {
  width: 500px;
}

.faxHeader {
  display: flex;
  margin-bottom: 10px;
  cursor: default;
  > * {
    margin-right: 10px;
  }
}

.faxObject {
  width: 500px;
  height: 400px;
}
