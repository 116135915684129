.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.subPage {
  flex: 1;
  overflow-y: scroll;
  padding: 20px;
}

.bottomNavigation {
  flex: none;
}